import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import classNames from 'classnames';
import _ from 'lodash';
import s from './Image.module.scss';

const Image = ({
    src = '',
    width = null,
    height = null,
    alt = '',
    caption = '',
    focal = {},
    srcset = [],
    sizes = [],
    style = {},
    useCover = false,
    className = '',
    lazyLoad = true,
    priority = false,
    asImg = false,
    overrideSrc = true,
    setLoaded = () => {},
}) => {
    const [useObjectFit, setUseObjectFit] = useState(true);

    useEffect(() => {
        // Fallback for object fit, e.g. ie11
        const styles = document.documentElement.style;
        if('objectFit' in styles === false) {
            setUseObjectFit(false);
        }
    }, []);

    const position = !_.isEmpty(focal) ? `${focal.x} ${focal.y}` : 'center center';

    const classes = classNames(
        [s['Image']],
        {[s['Image--Fallback']]: !useObjectFit},
        {[s['Image--ObjectFit']]: useObjectFit},
        {[s['Image--Cover']]: useCover},
        {[s['Image--Contain']]: !useCover},
        className,
    );

    if(!useObjectFit) {
        return (
            <div
                className={classes}
                style={{
                    ...style,
                    backgroundImage: `url('${src}')`,
                    backgroundPosition: position,
                }}
                title={caption}
            >
                <img
                    className={s['Image__LoadEvent']}
                    src={src}
                    alt=""
                    onLoad={() => setLoaded()}
                />
            </div>
        );
    }

    if(asImg) {
        return (
            <img
                className={classes}
                width={width}
                height={height}
                src={src}
                alt={alt}
                title={caption}
                srcSet={srcset.join(', ')}
                sizes={sizes.join(', ')}
                style={{
                    ...style,
                    objectPosition: position,
                }}
                loading={lazyLoad ? 'lazy' : null}
                onLoad={() => setLoaded(true)}
            />
        );
    }

    return (
        <NextImage
            className={classes}
            src={src}
            overrideSrc={overrideSrc ? src : undefined} // Override src to keep original as src
            alt={alt}
            width={useCover || !width ? undefined : width}
            height={useCover || !height ? undefined : height}
            title={caption}
            sizes={sizes.join(', ')}
            loading={lazyLoad ? 'lazy' : 'eager'}
            priority={priority}
            fill={useCover}
            onLoad={() => setLoaded(true)}
            style={{
                ...style,
                objectPosition: position,
            }}
        />
    );
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
    caption: PropTypes.string,
    focal: PropTypes.object,
    srcset: PropTypes.array,
    sizes: PropTypes.array,
    style: PropTypes.object,
    useCover: PropTypes.bool,
    className: PropTypes.string,
    lazyLoad: PropTypes.bool,
    priority: PropTypes.bool,
    overrideSrc: PropTypes.bool,
    asImg: PropTypes.bool,
    setLoaded: PropTypes.func,
};

export default Image;
